<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :key="resetTab" :list="statusTab" :filter.sync="filter" :status="basicStatusTab" title="Documentos emitidos" :breadcrumbs="breadcrumbs" :hiddenTab="!$helpers.hasSomePermission(['view_document'])" :scroll="scroll">
      <!-- <template v-slot:main v-if="$helpers.hasSomePermission(['view_document'])">
        <v-btn v-if="enabledCreateDTE && $store.state.auth.account.country !== 'PE'" color="primary" :to="{ name: 'DocumentsCreate' }" :ripple="false"><v-icon left>mdi-file-plus</v-icon>Crear documento</v-btn>
      </template> -->
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_document'])">
        <template>
          <v-btn class="mr-2" @click="getList" :disabled="!Object.keys($route.query).length" outlined>
            <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
          </v-btn>
          <VMainColumnRecordVisualization :headers.sync="headersAux" :pageSize.sync="pageSize" object="documents" />
          <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item class="px-3" @click="createExport" :disabled="!selectedDocuments.length" :ripple="false">
                <v-list-item-title class="body-1 fontDraw--text">Descargar documentos</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="createDownloadList" :disabled="!count" :ripple="false">
                <v-list-item-title class="body-1 fontDraw--text">Descargar listado</v-list-item-title>
              </v-list-item>
              <v-tooltip color="bgSearch" right max-width="185px" transition="scale-transition" open-delay="1000">
                <template v-slot:activator="{ on }">
                  <span class="fontDraw--text body-1" v-on="!selectedDocuments.length ? on : false">
                    <v-list-item class="px-3" @click="sendEmailDialog=true" :ripple="false" :disabled="!selectedDocuments.length">
                      <v-list-item-title class="body-1 fontDraw--text">Enviar documentos por mail</v-list-item-title>
                    </v-list-item>
                  </span>
                </template>
                <span class="d-block px-3 py-2">Debe seleccionar documentos antes de seleccionar una opción</span>
              </v-tooltip>
            </v-list>
          </v-menu>
          <v-query-builder class="ml-2" :query-map="filters" model="documents" :active.sync="activeFilter" />
        </template>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0 mt-25 pb-10">
      <documents-list :show-create="true" :hasPermission="$helpers.hasSomePermission(['view_document'])" @activeBtnFilter="activeFilter = true" :selectedDocuments.sync="selectedDocuments" :toggleDropdown.sync="toggleDropdown" :key="reset" :headerList="headerList" :pageSize="pageSize" @getList="getList" />
    </v-col>
    <!-- dialog export -->
    <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Descarga de documentos emitidos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-2">
          <v-list class="pa-0 transparent">
            <!-- <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="fontBody--text body-1 font-weight-bold">Resumen de la exportación</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item class="mt-2">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="fontBody--text body-1 font-weight-semibold">Selecciona el formato de los archivos:</v-list-item-title>
                <v-list-item-subtitle class="pt-2">
                  <v-row class="ml-n1" no-gutters>
                    <v-col cols="6" class="pa-0" v-for="file in fileFormat" :key="file">
                      <v-checkbox class="ma-0 vcheckbox" @click="getFileFormat(file)" :input-value="types.includes(file) || false" column :label="file" :disabled="['XLSX', 'CSV', 'XML'].includes(file) && count >= 1000000" dense hide-details />
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- alert -->
          <v-alert class="inf-alert-blue primary--text rounded-md mt-2" :height="58" dense text>
            <div class="d-flex pa-0 my-1">
              <v-icon color="primary" size="22">mdi-information-outline</v-icon>
              <div class="ml-4">
                <span class="body-2" style="line-height: 14px;">Esta acción
                  <span class="font-weight-bold">podría tardar varios minutos</span> en completarse. Recibirás un
                  <span class="font-weight-bold"> correo electrónico con el enlace de descarga.</span>
                </span>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="primary" @click="createExport" :ripple="false" :disabled="!types.length">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog export -->

    <!-- dialog send email new ui -->
    <v-dialog v-model="sendEmailDialog" width="700" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Envío de documentos por mail</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="sendEmailDialog=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-list class="v-list-form pa-0 transparent">
          </v-list>
          <div class="d-flex align-center">
            <span class="fontBody--text body-1" style="min-width: 60px;">Para</span>
            <v-combobox class="a-combobox" ref="email" v-model="messages.recipient" :key="key" hide-details :items="emailsList" outlined required hide-selected :placeholder="!messages.recipient.length ? 'Escribe el email del destinatario' : ''" @update:search-input="setEmail"  multiple deletable-chips small-chips dense single-line autocomplete="off">
              <template v-slot:selection="data">
                <v-chip class="pr-2 ma-1" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)" small>{{ data.item }}</v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-icon color="secondary">mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="ml-n2 font-weight-medium" v-html="data.item"></v-list-item-title>
              </template>
            </v-combobox>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="sendEmailDialog=false" outlined>Cancelar</v-btn>
          <v-btn @click="createMail" :loading="$store.state.messages.createLoader" color="primary">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { groupBy } from 'lodash'
import { saveAs } from 'file-saver'
import MiniHeader from '@/components/commons/MiniHeader'
import { documentsFilters } from '@/collections/queryBuilderFilters'
import DocumentTypes from '@/collections/documentTypes'
import documentStatuses from '@/collections/documentStatuses'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import VMainColumnRecordVisualization from '@/components/commons/VMainColumnRecordVisualization'
import ListViewMixin from '@/mixins/ListViewMixin'
import ReminderDocumentsEmail from '@/components/reminders/documents/Email'
import numeral from 'numeral'
import moment from 'moment'
const REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export default {
  components: {
    VMainColumnRecordVisualization,
    DocumentsList,
    VQueryBuilder,
    MiniHeader,
    ReminderDocumentsEmail
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    resetTab: 0,
    key: 0,
    emailsList: [],
    pageSize: null,
    headerList: [],
    activeFilter: false,
    reset: 0,
    toggleDropdown: null,
    sendEmailList: [
      {
        name: 'Mail de emisión (intercambio)',
        id: 'taxpayer'
      },
      {
        name: 'Mail de contactos',
        id: 'customer'
      }
    ],
    selectedDocuments: [],
    sendEmailDialog: false,
    scroll: 0,
    filter: 0,
    dialogExport: false,
    filters: documentsFilters,
    documentTypes: DocumentTypes,
    documentStatuses,
    loading: false,
    types: [],
    messages: {
      text: '',
      recipient: '',
      subject: '',
      type: ['pdf', 'xml']
    },
    status: [
      {
        title: 'Procesando',
        icon: 'mdi-sync',
        countries: ['CL', 'PE']
      },
      {
        title: 'Aceptado',
        icon: 'mdi-check',
        countries: ['CL', 'PE']
      },
      {
        title: 'Pendiente',
        icon: 'mdi-clock',
        countries: ['PE']
      },
      {
        title: 'Rechazado',
        icon: 'mdi-close',
        countries: ['CL', 'PE']
      },
      {
        title: 'Anulado',
        icon: 'mdi-file-remove',
        countries: ['CL', 'PE']
      }
    ],
    breadcrumbs: {
      main: 'Emisión',
      children: [
        {
          text: 'Documentos'
        }
      ]
    },
    quantitySelectedDocumentTypeForSendEmail: '',
    fileFormat: ['XLSX', 'CSV', 'XML'], // 'PDF',
    headers: [
      { text: 'Receptor', value: 'customer_name', sortable: false, show: true, disabled: false, label: 'Receptor', fixed: true },
      { text: 'Tipo de documento', value: 'type', sortable: true, show: true, disabled: false, label: 'Tipo de documento', fixed: true },
      { text: 'Folio#', value: 'number', align: 'end', sortable: true, show: true, disabled: false, label: 'Folio #', fixed: true },
      { text: 'Fecha del doc.', value: 'date', align: 'end', sortable: true, show: true, disabled: false, label: 'Fecha del documento', fixed: true },
      { text: 'Fecha de crea.', value: 'created', align: 'end', sortable: true, show: false, disabled: false, label: 'Fecha de creación', fixed: false },
      { text: 'Estado', value: 'status', show: true, disabled: false, label: 'Estado', fixed: true, width: 150 },
      { text: 'Total', value: 'amount_total', align: 'end', sortable: true, show: true, disabled: false, label: 'Total', fixed: true },
      { text: '', value: 'actions', align: 'end', show: true}
    ],
    headersAux: []
  }),
  computed: {
    ...mapState({
      count: state => state.documents.documentsList.length
    }),
    statusTab () {
      const list = this.documentStatuses?.filter(({countries, id}) => !['INV', 'PRE'].includes(id) && countries?.some((c) => c === this.$store.state?.auth?.account?.country))
      return Object.entries(groupBy(list, 'summary')).map(([key, value]) => {
        return { key, count: value.length, children: value }
      })
    },
    basicStatusTab () {
      return this.status.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
    },
    activeDocument () {
      return (id) => {
        return DocumentTypes.find(d => d.id === id)
      }
    },
    checkPermissionDTE () {
      if (!!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return !ownGroupPermissions.filter(({permission}) => permission === 'view_document').length
      }
      return false
    },
    enabledCreateDTE () {
      if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return ownGroupPermissions.filter(({permission}) => permission === 'add_document')
      }
      return true
    }
  },
  watch: {
    pageSize: {
      handler (val) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (currentQuery?.name) delete currentQuery.name
        if (val && currentQuery.page) {
          delete currentQuery.page
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: currentQuery }).catch(() => {})
        } else if (Object.keys(currentQuery)?.length) this.getList()
      },
      inmediate: true
    },
    headersAux: {
      handler (val) {
        this.headerList = val.filter(({show}) => show)
      },
      inmediate: true
    },
    filter: {
      handler (val) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (val > 0) {
          delete currentQuery.page
          const list = this.documentStatuses?.filter(({countries, isAX}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country) && isAX)
          let query = list.filter((item) => item.summary === this.basicStatusTab[val - 1].title).map((item) => item.id) // .toString()
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: {...currentQuery, status: query} }).catch(() => {})
        } else {
          delete currentQuery.status
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: currentQuery }).catch(() => {})
        }
      },
      inmediate: false
    },
    selectedDocuments (val) {
      this.messages.subject = `Envía ${this.activeDocument(val[0]?.document_type)?.name ?? ''} N° ${val[0]?.number}`
      this.messages.text = `Estimado cliente, ${val[0]?.customer?.name ?? ''},\n\n`
      this.messages.text += `Adjunto ${this.activeDocument(val[0]?.document_type)?.name ?? ''} N° ${val[0]?.number}, `
      this.messages.text += `correspondiente al ${moment(val[0]?.date).format('DD MMM YYYY')} `
      this.messages.text += `por un monto de ${numeral(val[0]?.total).format('0,0')} ${val[0]?.exchange?.currency_from ?? val[0]?.exchange?.currency_to ?? this.activeDocument(val[0]?.document_type)?.currencies[0] ?? ''} \n\nAtentamente ${this.$store.state?.auth?.account?.name}`
    },
    $route () {
      if (!this.$route.query?.status) {
          this.filter = 0
          this.resetTab += 1
      } else this.reset += 1

      if (!this.$route.query?.name) {
        this.breadcrumbs = {
          main: 'Emisión',
          children: [
            {
              text: 'Documentos'
            }
          ]
        }
      }
    }
  },
  created () {
    if (this.$route.query.status) {
      this.statusTab.forEach((item, index) => {
        if (item.children.filter(({isAX}) => isAX).map(({id}) => id).toString() === this.$route.query.status.toString()) {
          this.filter = index + 1
        }
      })
    }
    if ((Object.keys(this.$route?.query)?.length)) {
      this.getList()
    }
    this.setFilterDocumentType()
    this.filters = this.filters?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))

    if (this.$route.query.status__code__in) {
      const query = this.$route.query.status__code__in.split(',')[0]
      this.statusTab.forEach((item) => {
        const statusAux = item.children.map((child) => child.code.toString())[0]
        if (query === statusAux) {
          const index = this.status.findIndex(({title}) => title === item.key)
          this.filter = index !== -1 ? index < `${this.$store.state?.auth?.account?.country === 'CL' ? 3 : 5}` ? index + 1 : index : 0
        }
      })
    }
    if (this.$route.query.posdocument__pos__branch__id) {
      this.breadcrumbs.children.push({
        text1: this.$route.query._name,
        route1: { name: 'BranchRetrieveUpdate', params: { id: this.$route.query.posdocument__pos__branch__id, ...this.$route.params } }
      })
    }

    if (this.$route.query.posdocument__pos__id) {
      this.breadcrumbs.children.push({
        text1: this.$route.query._name,
        route1: { name: 'PosRetrieveUpdate', params: { id: this.$route.query.posdocument__pos__branch__id, ...this.$route.params } }
      })
    }

    if (this.$route.query.batchdocument__batch) {
      this.breadcrumbs.children = [
        { text: 'Facturación por lotes', route: { name: 'DocumentsBatchRetrieve', params: { id: this.$route.query.batchdocument__batch, ...this.$route.params }} },
        { text1: this.$route.query.batchdocument__batch },
        { text2: 'Documentos'}
      ]
    }

    if (this.$route.query.customer) {
      this.breadcrumbs.children = [
        { text: 'Clientes', route: { name: 'ContactRetrieveUpdate', params: { id: this.$route.query.customer, ...this.$route.params }} },
        { text1: 'Documentos'}
      ]
    }

    if (this.$route.query._name) {
        this.breadcrumbs.children.push({
        text1: this.$route.query._name ?? '',
        route1: { name: 'DocumentsRetrieve', params: { id: this.$route.query._name, ...this.$route.params } }
      })
    }

    this.headersAux = [...this.headers]
  },
  methods: {
    getFileFormat (val = null) {
      if (!this.types?.find((s) => s === val)) this.types.push(val)
      else {
        this.types?.splice(this.types?.indexOf(val), 1)
        this.types = [...this.types]
      }
    },
    setEmail (e) {
      let array = []
      this.emailsList = []

      if (e?.length) {
        let elm = e.replace(/ /g, ',')
        array = [...array, ...elm.split(',')]
        array = array.filter((item) => Boolean(item))

        if (array.length === 1) {
          if (REGEX.test(e)) {
            this.emailsList.push(e)
            this.emailsList = [...new Set([...this.emailsList])]
          }
        } else {
          this.key += 1
          this.messages.recipient = [...this.messages.recipient, ...array.filter(item => REGEX.test(item))]
          this.messages.recipient = [...new Set(this.messages.recipient)]
        }
      }
    },
    remove (item) {
      const index = this.messages.recipient.indexOf(item)
      if (index >= 0) {
        this.messages.recipient.splice(index, 1)
      }
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      if (!this.checkPermissionDTE && Object.keys(this.$route?.query)?.length > 0) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        Object.keys(currentQuery).forEach((key) => {
          if (['posdocument__pos__branch__id', 'posdocument__pos__id', 'batchdocument__batch'].includes(key)) delete currentQuery._name
        })
        if (currentQuery?.ref) delete currentQuery.ref
        if (currentQuery?._name) delete currentQuery._name
        this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {...currentQuery, ...(this.pageSize && { page_size: this.pageSize})}
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
          this.hasPermission = error.toString().search('403') !== -1
        })
        .finally(() => {
          this.reset += 1 
        })
      } else {
        this.$store.commit('documents/SET_LIST', { response: {}, resource: 'documents' })
      }
    },
    setFilterDocumentType () {
      const currentDocumentType = this.documentTypes.filter(({country}) => country === this.$store.getters['base/currentCountry'])
      const index = this.filters.findIndex(({id}) => id === 'type')
      if (currentDocumentType.length) {
         this.filters[index].choices = currentDocumentType.map(({name, id}) => {
          return {
            label: name,
            value: id
          }
        })
      }
    },
    createExport () {
      if (this.selectedDocuments.length > 25) {
        this.$dialog.message.error('Solo se pueden descargar un máximo de 25 documentos.')
      } else {
        let currentQuery = this.deleteQueries()
        this.$store.dispatch('accounts/DOWNLOAD', {
          resource: 'documents/download',
          query: currentQuery,
          payload: {
            document_ids: this.selectedDocuments.map(({id}) => id)
          }
        })
        .then(() => {
          this.$dialog.message.info('La descarga se ha realizado con éxito.')
        })
        .catch(() => {
          this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
        })
      }
    },
    createDownloadList () {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.page) delete currentQuery.page
      if (currentQuery.pageSize) delete currentQuery.pageSize
        this.$store.dispatch('accounts/LIST', {
          resource: 'documents',
          query: {...currentQuery, ...(!this.$route.query?.ordering && { ordering: ['-date', '-created']}), ...{ format: 'CSV' }},
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], {type: "application/csv"})
          saveAs(blob, 'documentos.csv')
          this.$dialog.message.info('La descarga se ha realizado con éxito.')
        })
        .catch(() => {
          this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
        })
        .finally(() => {
          this.reset += 1 
        })
    },
    createMail () {
      let currentQuery = this.deleteQueries()
      this.$store.dispatch('messages/CREATE', {
        resource: 'documents/distribute',
        query: currentQuery,
        payload: {
          document_ids: this.selectedDocuments.map(({id}) => id),
          email:  this.messages.recipient.toString()
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de documentos por email se realizó con éxito')
        this.messages.recipient = ''
        this.messages.type = []
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.sendEmailDialog = false
      })
    },
    deleteQueries () {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      if (currentQuery._name) delete currentQuery._name
      return currentQuery
    }
  }
}
</script>
